import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import deluxeWord from "../images/word-deluxe.svg"
import Theme from "../lib/Theme"
import { LinkButton } from "./Button"
import { Heading } from "./Heading"

interface Props {
  id: string
  expanded?: boolean
}

export const PackageCard = ({ id, expanded, ...props }: Props) => {
  const result = useStaticQuery(graphql`
    query {
      kirby {
        allPackage {
          id
          contents
          highlight
          highlightText
          excerpt
          title
          price
          pricePerPerson
        }
      }
    }
  `)

  const data = result.kirby.allPackage.find((item) => item.id === id)

  const highlight = data.highlight && !expanded

  return (
    <Wrap highlight={highlight} {...props}>
      {highlight && <Highlight>{data.highlightText}</Highlight>}

      {data.title === "Deluxe" ? (
        <Deluxe src={deluxeWord} alt="Deluxe" />
      ) : (
        <Title size={32} as="h3">
          {data.title}
        </Title>
      )}

      {!expanded && <Intro>{data.excerpt}</Intro>}
      <PriceInfo>
        {data.pricePerPerson ? (
          <>
            vanaf <strong>€{data.price}</strong> per persoon, per uur*
          </>
        ) : (
          <>
            vanaf <strong>€{data.price}</strong>
          </>
        )}
      </PriceInfo>
      <ListLabel>Dit pakket bevat:</ListLabel>
      <ListWrap expanded={expanded}>
        {data.contents.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ListWrap>

      {!expanded && (
        <Cta>
          <LinkButton
            to={`/${id}`}
            data-track-content
            data-content-name="PackageCard"
            data-content-piece={data.title}
          >
            Ontdek {data.title} pakket
          </LinkButton>
        </Cta>
      )}
    </Wrap>
  )
}

const Wrap = styled.div<{ highlight: boolean }>`
  position: relative;
  background: #fff;
  border-radius: 5px;
  text-align: center;
  padding: 2rem 2rem 0 2rem;

  ${(p) =>
    p.highlight &&
    css`
      margin: -2rem 0;
      border: 3px solid ${Theme.Colors.pink.s100};

      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07),
        0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07),
        0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    `}

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.11), 0 2px 2px rgba(0, 0, 0, 0.11),
    0 4px 4px rgba(0, 0, 0, 0.11), 0 8px 8px rgba(0, 0, 0, 0.11),
    0 16px 16px rgba(0, 0, 0, 0.11), 0 32px 32px rgba(0, 0, 0, 0.11);
`

const Highlight = styled.p`
  color: #fff;
  background: ${Theme.Colors.pink.s100};
  text-transform: uppercase;
  padding: 0.25rem;
  font-size: 0.9rem;
  font-family: ${Theme.Font.body.family};
  font-weight: ${Theme.Font.body.bold};
  margin: -2.1rem -2.1rem 2rem -2.1rem;
`

const Title = styled(Heading)`
  margin: 0 0 1rem 0;
  color: ${Theme.Colors.purple.s100};
  text-align: center;
`

const Deluxe = styled.img`
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 4rem;
  margin-bottom: 1rem;
`

const Intro = styled.p`
  font-size: 0.9rem;
  margin: 0;
`

const PriceInfo = styled.p`
  margin: 1rem 0;
  color: ${Theme.Colors.purple.s100};
  line-height: 1.2;
  font-size: 0.9rem;

  strong {
    display: block;
    font-size: 2.5rem;
  }
`

const ListLabel = styled.p`
  text-align: left;
  font-size: 0.8em;
  color: ${Theme.Colors.grey.s50};
  margin: 0 0 0.5rem 0;
`

const ListWrap = styled.ul<{ expanded: boolean }>`
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;

  ${(p) =>
    p.expanded
      ? css`
          padding-bottom: 2rem;
        `
      : css`
          max-height: 15rem;
          overflow: hidden;
        `}

  font-size: 0.9rem;

  li {
    border-top: 1px solid ${Theme.Colors.grey.s10};
    padding: 0.5rem 0;
  }
`

const Cta = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 10rem;
  padding: 1rem;
  border-radius: 0 0 1rem 1rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  a {
    display: block;
  }

  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
`
